import { Link, useNavigate } from "react-router-dom";
import { StaticImage } from "../../utils/image";
import { useDispatch, useSelector } from "react-redux";
import { UsersData } from "../../data/users";
import { Fragment, useEffect, useState } from "react";
import ApiAccount from "../../api/ApiAccount";
import CSwl from "../../utils/alert";
import ApiUser from "../../api/ApiUser";
import { setLocalStorage } from "../../utils/localStorage";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
} from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { validateNumber, validatePhonenumber } from "../../utils";
import Select from 'react-tailwindcss-select';
import Modal from "../../conponents/Modal";
import ApiJob from "../../api/ApiJob";
import FooterLogin from "../../conponents/FooterLogin";
import Swal from "sweetalert2";
const Login = () => {
    const { instance, accounts, inProgress } = useMsal();
    const [loginChannel, setLoginChannel] = useState(0);
    const [truckType, settruckType] = useState([]);
    const [state, setstate] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        newPassword: "",
        driverName: "",
        messageError: "",
        pincode: "",
        confirmPincode: "",
        showLogin: true,
        showRegister: false,
        showChangePassword: false,
        showOTP: false,
        showResetPassword: false,
        showChangePincode: false,
        adminLogin: false,
        userList: [],
        officerCode: "",
        phoneNumber: "",
        otp: "",
        confirmOTP: "",
        showOTPRegister: "",
        truckType: 0
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isAuthenticated = useIsAuthenticated();

    const [query, setQuery] = useState('')

    const filteredPeople =
        query === ''
            ? state.userList
            : state.userList.filter((person) => {
                return person.name.toLowerCase().includes(query.toLowerCase())
            })


    useEffect(() => {
        var url_string = window.location;
        var url = new URL(url_string);
        var q = url.searchParams.get("username");
        if (isAuthenticated && !q) {
            const username = accounts[0] && accounts[0].username;
            var model = {
                email: username,
            };

            loginAD(model);
        } else {
        }
    }, [isAuthenticated]);

    useEffect(() => {
        const cssScript = document.createElement('link');
        const script = document.createElement('script');
        const script2 = document.createElement('script');
        //cssScript.href = "https://scgpdpa.scg.com/assets/cookie.css";
        cssScript.href = `${process.env.REACT_APP_URL.replace("loginSuccess","")}cookieOverrideStyleScgp.css`;
        cssScript.rel = "stylesheet";
        cssScript.async = true;
        script.src = "https://scgppdpa.scgpco.com/v1/privacy-managers/6a0/reddot.js";
        script2.src = `${process.env.REACT_APP_URL.replace("loginSuccess","")}reddot-consent-display.js?v=2`;
        script.async = true;
        script2.async = true;
        document.body.appendChild(cssScript);
        document.body.appendChild(script);
        document.body.appendChild(script2);
        setTimeout(() => {
            //newCookie()
        }, 500);
        return () => {
            document.body.removeChild(script);
        }
    }, []);

    const newCookie = () => {
        const script2 = document.createElement('script');
        script2.async = true;
        script2.innerHTML = `new CookieGenerateFunction("PKG-APP-EDN-TEST","th");`;
        document.body.appendChild(script2);
    }

    useEffect(() => {

        var url_string = window.location;
        var url = new URL(url_string);
        var q = url.searchParams.get("username");
        var officer_code = url.searchParams.get("officer_code");
        if (q) {
            loginADFrontend({
                email: q,
                officer_code: officer_code
            })
        }
    }, []);

    useEffect(() => {
        getTruckType()
    }, []);

    const getTruckType = async () => {
        CSwl.SwalLoad();
        let response = {};
        response = await ApiJob.getTruckType();
        if (response.status == 200) {
            settruckType(response.data)
            CSwl.SwalClose();
        }
    }

    const navigateToHome = async (e) => {
        e.preventDefault();
        CSwl.SwalLoad();
        let response = {};
        var url_string = window.location;
        var url = new URL(url_string);
        var q = url.searchParams.get("username");
        var officer_code = url.searchParams.get("officer_code");
        if (state.adminLogin) {
            response = await ApiAccount.loginAs({
                "officerCode": state.email?.trim(),
                "officerPassword": state.password,
                "LoginAs": state.officerCode
            });
        } else {
            response = await ApiAccount.login({
                "officerCode": state.email?.trim(),
                "officerPassword": state.password,
                "truckid": "",
                "drivername": "",
                "truckName": "",
                "trucktype": "",
                "roleid": "",
                "contractId": ""
            });
        }

        if (response.status == 200) {
            if (response.data.value.isAdmin) {
                setstate({ ...state, adminLogin: true, userList: response.data.value.users, messageError: "", whExport: false })
                CSwl.SwalClose();
            } else if (response.data.value.isWhExport) {
                setLocalStorage("token", response.data.value.token);
                const responseUser = await ApiUser.getProfile();
                dispatch({
                    type: "set", user: responseUser.data, token: response.data.value.token,
                    messageError: "",
                    adminLogin: false,
                    email: q,
                    officerCode: officer_code ? officer_code : (state.officerCode ? state.officerCode : state.email?.trim()),
                    whExport: true,
                    searchWh : {
                        PI_NO: "",
                        EO_NO: "",
                        DP_NO: "",
                        LOAD_DATE: "",
                        TruckId: ""
                    }
                });
                navigate('/exportData');
                CSwl.SwalClose();
            } else {
                setstate({ ...state, adminLogin: false, userList: [] })
                setLocalStorage("token", response.data.value.token);
                const responseUser = await ApiUser.getProfile();
                if (responseUser.status == 200) {
                    dispatch({
                        type: "set", user: responseUser.data, token: response.data.value.token,
                        messageError: "",
                        adminLogin: state.adminLogin,
                        email: q,
                        whExport: false,
                        officerCode: officer_code ? officer_code : (state.officerCode ? state.officerCode : state.email?.trim()),
                    });
                    navigate('/');
                }
                CSwl.SwalClose();
            }
        } else if (response.status == 404) {
            CSwl.SwalClose();
            var result = await Swal.fire({
                title: "",
                icon: "warning",
                customClass: {
                    confirmButton: "btn btn-success ml-2",
                    cancelButton: "btn btn-danger",
                },
                html:"ข้อมูลไม่มีในระบบ กรุณาติดต่อ Admin เพื่อทำการ Sync จากระบบ LMS หรือกดตกลงเพื่อยืนยันที่จะสร้างบัญชีใหม่",
                // buttonsStyling: false,
                confirmButtonText: "ยืนยัน",
                cancelButtonText: "ยกเลิก",
                reverseButtons: true,
                showCancelButton: true,
            });
            if(result.isConfirmed){
                setstate({ ...state, showLogin: false, showRegister: true })
            }
        } else {
            CSwl.SwalClose();
            if (response.data?.includes("Change Password")) {
                setstate({ ...state, showLogin: false, showChangePassword: true });
            }
            else if (response.data?.includes("Change Pincode")) {
                setstate({ ...state, showLogin: false, showChangePassword: true, showChangePincode: true });
            }
            else if (response.data?.includes("Backoffice")) {
                window.location.href = process.env.REACT_APP_URL_BACKEND + `#/login?username=${state.email?.trim()}`
            }

            else {
                setstate({ ...state, messageError: response.data });
            }

            //CSwl.SwalErr(response.data);
        }
        // const user = UsersData.find(x => x.email == state.email && x.password == state.password);
        // if (!user) { setstate({ ...state, messageError: "Email or Password invalid", password: "" }); return false; }
        // else {
        //     setstate({ ...state, messageError: "" });
        //     dispatch({ type: "set", user: user });
        //     navigate('/');
        // }

    }

    const whLogin = async (e) => {
        e.preventDefault();
        CSwl.SwalLoad();
        let response = {};
        var url_string = window.location;
        var url = new URL(url_string);
        var q = url.searchParams.get("username");
        var officer_code = url.searchParams.get("officer_code");
        response = await ApiAccount.loginWhExport({
            "officerCode": state.email?.trim(),
            "officerPassword": state.password,
            "LoginAs": officer_code
        });

        if (response.status == 200) {
            setstate({ ...state, adminLogin: false, userList: [] })
            setLocalStorage("token", response.data.value.token);
            const responseUser = await ApiUser.getProfile();
            if (responseUser.status == 200) {
                dispatch({
                    type: "set", user: responseUser.data, token: response.data.value.token,
                    messageError: "",
                    adminLogin: false,
                    email: q,
                    officerCode: officer_code,
                    whExport: true,
                    searchWh : {
                        PI_NO: "",
                        EO_NO: "",
                        DP_NO: "",
                        LOAD_DATE: "",
                        TruckId: ""
                    }
                });
                navigate('/exportData');
            }
            CSwl.SwalClose();
        } else if (response.status == 404) {
            CSwl.SwalClose();
            setstate({ ...state, showLogin: false, showRegister: true })
        } else {
            CSwl.SwalClose();
            setstate({ ...state, messageError: response.data });
            //CSwl.SwalErr(response.data);
        }
        // const user = UsersData.find(x => x.email == state.email && x.password == state.password);
        // if (!user) { setstate({ ...state, messageError: "Email or Password invalid", password: "" }); return false; }
        // else {
        //     setstate({ ...state, messageError: "" });
        //     dispatch({ type: "set", user: user });
        //     navigate('/');
        // }

    }

    const loginAuto = async (username, password) => {
        CSwl.SwalLoad();
        let response = {};

        response = await ApiAccount.login({
            "officerCode": username,
            "officerPassword": password,
            "truckid": "",
            "drivername": "",
            "truckName": "",
            "trucktype": "",
            "roleid": "",
            "contractId": ""
        });

        if (response.status == 200) {
            if (response.data.value.isAdmin) {
                setstate({ ...state, adminLogin: true, userList: response.data.value.users, messageError: "" })
                CSwl.SwalClose();
            } else {
                setstate({ ...state, adminLogin: false, userList: [] })
                setLocalStorage("token", response.data.value.token);
                const responseUser = await ApiUser.getProfile();
                if (responseUser.status == 200) {
                    dispatch({ type: "set", user: responseUser.data, token: response.data.value.token, messageError: "", adminLogin: state.adminLogin, email: state.email?.trim(), officerCode: state.officerCode });
                    navigate('/');
                }
                CSwl.SwalClose();
            }
        } else if (response.data?.includes("Backoffice")) {
            window.location.href = process.env.REACT_APP_URL_BACKEND + `#/login?username=${state.email?.trim()}`
        }
    }

    const loginAD = async (model) => {
        window.location.href = process.env.REACT_APP_URL_BACKEND + `#/login?username=${model.email}`
        // CSwl.SwalLoad();
        // let response = {};
        // response = await ApiAccount.loginAD({
        //     "officerCode": "",
        //     "officerPassword": "",
        //     "officerEmail": model.email,
        //     "truckid": "",
        //     "drivername": "",
        //     "truckName": "",
        //     "trucktype": "",
        //     "roleid": "",
        //     "contractId": ""
        // });
        // if (response.status == 200) {
        //     window.location.href = process.env.REACT_APP_URL_BACKEND + `#/login?username=${model.email}`
        //     // if (response.data.value.isAdmin) {
        //     //     window.location.href = process.env.REACT_APP_URL_BACKEND + `#/login?username=${model.email}`
        //     //     setLoginChannel(1);
        //     //     setstate({ ...state, email: model.email, adminLogin: true, userList: response.data.value.users })
        //     //     CSwl.SwalClose();
        //     // } else {
        //     //     setstate({ ...state, adminLogin: false, userList: [] })
        //     //     setLocalStorage("token", response.data.value.token);
        //     //     const responseUser = await ApiUser.getProfile();
        //     //     if (responseUser.status == 200) {
        //     //         dispatch({ type: "set", user: responseUser.data, token: response.data.value.token });
        //     //         navigate('/');
        //     //     }
        //     //     CSwl.SwalClose();
        //     // }
        // } else if (response.status == 404) {
        //     sessionStorage.clear()
        //     CSwl.SwalClose();
        //     navigate('/login');
        // } else {
        //     sessionStorage.clear()
        //     CSwl.SwalClose();
        //     setstate({ ...state, messageError: response.data });
        //     //CSwl.SwalErr(response.data);
        // }
    }

    const loginADFrontend = async (model) => {
        CSwl.SwalLoad();
        let response = {};
        response = await ApiAccount.loginADFrontEnd({
            "officerCode": model.officer_code,
            "officerPassword": "",
            "officerEmail": model.email,
            "truckid": "",
            "drivername": "",
            "truckName": "",
            "trucktype": "",
            "roleid": "",
            "contractId": ""
        });
        if (response.status == 200) {
            if (response.data.value.isAdmin) {
                setLoginChannel(2);
                setstate({ ...state, email: model.email, adminLogin: true, userList: response.data.value.users })
                CSwl.SwalClose();
            } else if (response.data.value.isWhExport) {
                setLocalStorage("token", response.data.value.token);
                const responseUser = await ApiUser.getProfile();
                dispatch({
                    type: "set", user: responseUser.data, token: response.data.value.token,
                    messageError: "",
                    adminLogin: false,
                    email: state.email,
                    officerCode: state.officerCode,
                    whExport: true,
                    searchWh : {
                        PI_NO: "",
                        EO_NO: "",
                        DP_NO: "",
                        LOAD_DATE: "",
                        TruckId: ""
                    }
                });
                navigate('/exportData');
                CSwl.SwalClose();
            } else {
                setstate({ ...state, adminLogin: false, userList: [] })
                setLocalStorage("token", response.data.value.token);
                const responseUser = await ApiUser.getProfile();
                if (responseUser.status == 200) {
                    dispatch({ type: "set", user: responseUser.data, token: response.data.value.token, adminLogin: state.adminLogin, email: state.email, officerCode: state.officerCode });
                    navigate('/');
                }
                CSwl.SwalClose();
            }
        } else if (response.status == 404) {
            sessionStorage.clear()
            CSwl.SwalClose();
            navigate('/login');
        } else {
            sessionStorage.clear()
            CSwl.SwalClose();
            setstate({ ...state, messageError: response.data });
            //CSwl.SwalErr(response.data);
        }
    }

    const createNewAccount = async (e) => {
        e.preventDefault();

        CSwl.SwalLoad();
        if (state.password !== state.confirmPassword) {
            setstate({ ...state, messageError: "ยืนยันรหัสผ่านไม่ตรงกัน" });
            CSwl.SwalClose();
            return false;
        }
        const response = await ApiUser.otpRequestRegister({
            "officer_code": state.email?.trim(),
            "phoneNumber": state.phoneNumber,
        });
        if (response.status == 200) {
            // console.log(response.data);
            CSwl.SwalClose();
            // CSwl.SwalSuccess();
            setstate({ ...state, showOTPRegister: true, messageError: "", otp: response.data, messageErrorOTP: "" })
        } else {
            CSwl.SwalClose();
            setstate({ ...state, messageError: response.data });
            //CSwl.SwalErr(response.data);
        }
        // const response = await ApiAccount.create({
        //     "officerCode": state.email,
        //     "password": state.password,
        //     "confirmPassword": state.confirmPassword,
        //     "driverName": state.driverName,
        //     "phoneNumber": state.phoneNumber,
        // });

        // if (response.status == 200) {
        //     CSwl.SwalClose();
        //     CSwl.SwalSuccess();
        //     setstate({ ...state, password: "", showLogin: true, showRegister: false, showChangePassword: false, messageError: "" })
        // } else {
        //     CSwl.SwalClose();
        //     setstate({ ...state, messageError: response.data });
        //     //CSwl.SwalErr(response.data);
        // }
        // const user = UsersData.find(x => x.email == state.email && x.password == state.password);
        // if (!user) { setstate({ ...state, messageError: "Email or Password invalid", password: "" }); return false; }
        // else {
        //     setstate({ ...state, messageError: "" });
        //     dispatch({ type: "set", user: user });
        //     navigate('/');
        // }

    }

    const createNewAccountConfirmOtp = async () => {
        CSwl.SwalLoad();
        if (state.otp != state.confirmOTP) {
            setstate({ ...state, messageErrorOTP: "ยืนยันรหัส OTP ไม่ถูกต้อง" });
            CSwl.SwalClose();
            return false;
        }
        const response = await ApiAccount.create({
            "officerCode": state.email?.trim(),
            "password": state.password,
            "confirmPassword": state.confirmPassword,
            "driverName": state.driverName,
            "phoneNumber": state.phoneNumber,
            "truckType": state.truckType,
        });
        if (response.status == 200) {
            CSwl.SwalClose();
            CSwl.SwalSuccess();
            await loginAuto(state.email?.trim(), state.password);
            setstate({ ...state, password: "", showLogin: true, showRegister: false, showChangePassword: false, messageError: "", messageErrorOTP: "", showOTPRegister: false })
        } else {
            CSwl.SwalClose();
            setstate({ ...state, messageError: response.data });
            //CSwl.SwalErr(response.data);
        }
        const user = UsersData.find(x => x.email == state.email?.trim() && x.password == state.password);
        if (!user) { setstate({ ...state, messageError: "Email or Password invalid", password: "" }); return false; }
        else {
            setstate({ ...state, messageError: "" });
            dispatch({ type: "set", user: user, adminLogin: state.adminLogin, email: state.email?.trim(), officerCode: state.officerCode });
            navigate('/');
        }
    }

    const changePassword = async (e) => {
        e.preventDefault();
        CSwl.SwalLoad();
        if (state.newPassword !== state.confirmPassword) {
            setstate({ ...state, messageError: "ยืนยันรหัสผ่านไม่ตรงกัน" });
            CSwl.SwalClose();
            return false;
        }
        if (state.pincode !== state.confirmPincode) {
            setstate({ ...state, messageError: "ยืนยัน Pincode ไม่ตรงกัน" });
            CSwl.SwalClose();
            return false;
        }
        const response = await ApiUser.changePassword({
            "officer_code": state.email?.trim(),
            "oldPassword": state.password,
            "newPassword": state.newPassword,
            "pincode": state.pincode,
        });
        if (response.status == 200) {
            CSwl.SwalClose();
            CSwl.SwalSuccess();
            await loginAuto(state.email?.trim(), state.newPassword);
            //setstate({ ...state, password: "", showLogin: true, showChangePassword: false, messageError: "" })
        } else {
            CSwl.SwalClose();
            setstate({ ...state, messageError: response.data });
            //CSwl.SwalErr(response.data);
        }

    }

    const requestOTP = async (e) => {
        e.preventDefault();
        CSwl.SwalLoad();
        const response = await ApiUser.otpRequest({
            "officer_code": state.email?.trim(),
        });
        if (response.status == 200) {
            console.log(response.data);
            CSwl.SwalClose();
            CSwl.SwalSuccess();
            setstate({ ...state, showResetPassword: false, showOTP: true, messageError: "", otp: response.data })
        } else {
            CSwl.SwalClose();
            setstate({ ...state, messageError: response.data });
            //CSwl.SwalErr(response.data);
        }
    }

    const resetPassword = async (e) => {
        e.preventDefault();
        CSwl.SwalLoad();
        if (state.otp != state.confirmOTP) {
            setstate({ ...state, messageError: "ยืนยันรหัส OTP ไม่ถูกต้อง" });
            CSwl.SwalClose();
            return false;
        }
        if (state.newPassword !== state.confirmPassword) {
            setstate({ ...state, messageError: "ยืนยันรหัสผ่านไม่ตรงกัน" });
            CSwl.SwalClose();
            return false;
        }
        const response = await ApiUser.resetPassword({
            "officer_code": state.email?.trim(),
            "newPassword": state.newPassword,
        });
        if (response.status == 200) {
            CSwl.SwalClose();
            CSwl.SwalSuccess();
            await loginAuto(state.email?.trim(), state.newPassword);
            //setstate({ ...state, password: "", showLogin: true, showOTP: false, otp: "", confirmOTP: "", messageError: "" })
        } else {
            CSwl.SwalClose();
            setstate({ ...state, messageError: response.data });
            //CSwl.SwalErr(response.data);
        }
    }


    function handleLogin(instance) {
        instance.loginRedirect(loginRequest).catch(e => {
            console.error(e);
        });
    }

    function handleLogout(instance) {
        instance.logoutRedirect().catch(e => {
            console.error(e);
        });
    }

    return <>
        {
            (!state.showRegister && !state.showChangePassword && !state.showResetPassword && !state.showOTP) &&
            <form onSubmit={navigateToHome} className={`${state.showLogin ? "" : "hidden"}`}>
                <section className="h-screen">
                    <div className="h-full bg-gradient-to-r from-blue to-blue-600 flex justify-center items-center">
                        <div className="p-5 bg-white rounded flex flex-col items-center">
                            {loginChannel != 0 && <span onClick={() => loginChannel == 1 && state.adminLogin ? setLoginChannel(2) : setLoginChannel(0)} className="text-base text-blue cursor-pointer self-start">{'ย้อนกลับ'}</span>}

                            <div>
                                <img className="object-contain h-20 w-32" src={StaticImage('/images/logoSCGP.svg')} />
                            </div>
                            <div>
                                <h1 className="font-bold text-3xl">Sign in to SCGP e-DP System</h1>
                            </div>
                            <span className="text-base text-gray">ยินดีต้อนรับเข้าสู่ระบบบริหารเอกสารดิจิตอล</span>
                            {
                                loginChannel == 0 && <div className="w-72 flex flex-col gap-y-3 mt-3">
                                    <button onClick={() => handleLogin(instance)} type="button" className="btn-red w-72">SCG USER</button>
                                    <button onClick={() => setLoginChannel(1)} type="button" className="btn-red w-72">PRIVATE USER</button>
                                </div>
                            }
                            {loginChannel == 1 &&
                                <>
                                    {
                                        !state.adminLogin && <>
                                            <input maxLength={15} value={state.email?.trim()}
                                                onChange={(e) => {
                                                    setstate({ ...state, email: e.target?.value?.toUpperCase() })
                                                }} required className="form-control w-72 mt-2" placeholder="Username" type="text" disabled={state.adminLogin} />
                                            <input value={state.password}
                                                onChange={(e) => {
                                                    setstate({ ...state, password: e.target.value })
                                                }} required className="form-control w-72 mt-1" placeholder="Password" type="password" disabled={state.adminLogin} />
                                            <span onClick={() => setstate({ ...state, showLogin: false, showChangePassword: false, showRegister: false, showResetPassword: true })} className="text-base text-blue cursor-pointer self-start underline mt-2">{'ลืมรหัสผ่าน'}</span>
                                        </>
                                    }


                                    {state.messageError && <label className="text-red">{state.messageError}</label>}
                                    {
                                        state.adminLogin &&
                                        <div className="w-72">
                                            <Select
                                                isSearchable
                                                value={state.userList.map((x => {
                                                    return {
                                                        label: x,
                                                        value: x
                                                    }
                                                })).find(x => x.value == state.officerCode)}
                                                onChange={(e) => {
                                                    setstate({ ...state, officerCode: e.value })
                                                }}
                                                options={state.userList.map((x => {
                                                    return {
                                                        label: x,
                                                        value: x
                                                    }
                                                }))}
                                            />
                                        </div>
                                    }


                                    <button type="submit" className="btn-red w-72 mt-2">Sign in</button>

                                </>
                            }
                            {loginChannel == 2 &&
                                <>
                                    <button onClick={() => {
                                        setLoginChannel(1)
                                    }} type="button" className="btn-red w-72 mt-2">เข้าระบบ e-DP</button>
                                    <button onClick={whLogin} type="button" className="btn-red w-72 mt-2">ถ่ายภาพสินค้าส่งออก</button>
                                </>
                            }
                        </div>
                    </div>
                </section>
            </form>
        }
        {
            state.showRegister && <form onSubmit={createNewAccount}>
                <section className="h-screen">
                    <div className="h-full bg-gradient-to-r from-blue to-blue-600 flex justify-center items-center">
                        <div className="p-5 bg-white rounded flex flex-col items-center">
                            <div>
                                <img className="object-contain h-20 w-32" src={StaticImage('/images/logoSCGP.svg')} />
                            </div>
                            <div>
                                <h1 className="font-bold text-3xl">Create new account</h1>
                            </div>
                            <span className="text-base text-gray">ยินดีต้อนรับเข้าสู่ระบบบริหารเอกสารดิจิตอล</span>
                            <input maxLength={15} value={state.email?.trim()}
                                onChange={(e) => {
                                    setstate({ ...state, email: e.target?.value?.toUpperCase() })
                                }} required className="form-control w-72 mt-2" placeholder="Username" type="text" disabled={!state.showLogin} />
                            <input value={state.password}
                                onChange={(e) => {
                                    setstate({ ...state, password: e.target.value })
                                }} required className="form-control w-72 mt-1" placeholder="Password" type="password" />
                            <input value={state.confirmPassword}
                                onChange={(e) => {
                                    setstate({ ...state, confirmPassword: e.target.value })
                                }} required className={`${state.showLogin ? "hidden" : ""} form-control w-72 mt-1`} placeholder="Confirm Password" type="password" />
                            <input value={state.driverName}
                                onChange={(e) => {
                                    setstate({ ...state, driverName: e.target.value })
                                }} required className={`${state.showLogin ? "hidden" : ""} form-control w-72 mt-1`} placeholder="Driver Name" type="text" />
                            <input value={state.phoneNumber}
                                onChange={(e) => {
                                    if (validateNumber(e.target.value)) {
                                        setstate({ ...state, phoneNumber: e.target.value })

                                    }
                                }} required className={`${state.showLogin ? "hidden" : ""} form-control w-72 mt-1`} placeholder="Phone number" type="text" />
                            {
                                !state.showLogin && <div className="mt-2 w-72">
                                    <Select
                                        placeholder="เลือกประเภทรถ"
                                        isSearchable
                                        value={truckType.map((x => {
                                            return {
                                                label: x.description,
                                                value: x.truckId
                                            }
                                        })).find(x => x.value == state.truckType)}
                                        onChange={(e) => {
                                            setstate({ ...state, truckType: e.value })
                                        }}
                                        options={truckType.map((x => {
                                            return {
                                                label: x.description,
                                                value: x.truckId
                                            }
                                        }))}
                                    />
                                </div>
                            }

                            {state.messageError && <label className="text-red">{state.messageError}</label>}
                            <button type="submit" className="btn-red w-72 mt-2">Create new account</button>
                            <button type="button" onClick={(e) => { setstate({ ...state, showLogin: true }) }} className="btn-red w-72 mt-2">Sign in</button>
                        </div>
                    </div>
                </section>
            </form>
        }
        {
            state.showChangePassword && <form onSubmit={changePassword}>
                <section className="h-screen">
                    <div className="h-full bg-gradient-to-r from-blue to-blue-600 flex justify-center items-center">
                        <div className="p-5 bg-white rounded flex flex-col items-center">
                            <div>
                                <img className="object-contain h-20 w-32" src={StaticImage('/images/logoSCGP.svg')} />
                            </div>
                            <div>
                                <h1 className="font-bold text-3xl">Change Password</h1>
                            </div>
                            <span className="text-base text-gray">ยินดีต้อนรับเข้าสู่ระบบบริหารเอกสารดิจิตอล</span>
                            <input maxLength={15} value={state.email?.trim()}
                                onChange={(e) => {
                                    setstate({ ...state, email: e.target?.value?.toUpperCase() })
                                }} required className="form-control w-72 mt-2" placeholder="Username" type="text" disabled={!state.showLogin} />
                            <input value={state.password}
                                onChange={(e) => {
                                    setstate({ ...state, password: e.target.value })
                                }} required className="form-control w-72 mt-1" placeholder="Password" type="password" />
                            <input value={state.newPassword}
                                onChange={(e) => {
                                    setstate({ ...state, newPassword: e.target.value })
                                }} required className={`${state.showLogin ? "hidden" : ""} form-control w-72 mt-1`} placeholder="New Password" type="password" />
                            <input value={state.confirmPassword}
                                onChange={(e) => {
                                    setstate({ ...state, confirmPassword: e.target.value })
                                }} required className={`${state.showLogin ? "hidden" : ""} form-control w-72 mt-1`} placeholder="Confirm Password" type="password" />
                            {
                                state.showChangePincode && <>
                                    <input maxLength={4} value={state.pincode}
                                        onChange={(e) => {
                                            setstate({ ...state, pincode: e.target.value })
                                        }} required className={`${state.showLogin ? "hidden" : ""} form-control w-72 mt-1`} placeholder="New Pincode" type="password" />
                                    <input maxLength={4} value={state.confirmPincode}
                                        onChange={(e) => {
                                            setstate({ ...state, confirmPincode: e.target.value })
                                        }} required className={`${state.showLogin ? "hidden" : ""} form-control w-72 mt-1`} placeholder="Confirm Pincode" type="password" />
                                </>
                            }

                            {state.messageError && <label className="text-red">{state.messageError}</label>}
                            <button type="submit" className="btn-red w-72 mt-2">Change Password</button>
                            <button type="button" onClick={(e) => { setstate({ ...state, showLogin: true, showRegister: false, showChangePassword: false }) }} className="btn-red w-72 mt-2">Sign in</button>
                        </div>
                    </div>
                </section>
            </form>
        }

        {
            state.showResetPassword && <form onSubmit={requestOTP}>
                <section className="h-screen">
                    <div className="h-full bg-gradient-to-r from-blue to-blue-600 flex justify-center items-center">
                        <div className="p-5 bg-white rounded flex flex-col items-center">
                            <div>
                                <img className="object-contain h-20 w-32" src={StaticImage('/images/logoSCGP.svg')} />
                            </div>
                            <div>
                                <h1 className="font-bold text-3xl">Reset Password</h1>
                            </div>
                            <span className="text-base text-gray">ยินดีต้อนรับเข้าสู่ระบบบริหารเอกสารดิจิตอล</span>
                            <input maxLength={15} value={state.email?.trim()}
                                onChange={(e) => {
                                    setstate({ ...state, email: e.target?.value?.toUpperCase() })
                                }} required className="form-control w-72 mt-2" placeholder="Username" type="text" />
                            {state.messageError && <label className="text-red">{state.messageError}</label>}
                            <button type="submit" className="btn-red w-72 mt-2">Reset Password</button>
                            <button type="button" onClick={(e) => { setstate({ ...state, showLogin: true, showRegister: false, showChangePassword: false, showResetPassword: false, messageError: "" }) }} className="btn-red w-72 mt-2">Sign in</button>
                        </div>
                    </div>
                </section>
            </form>
        }

        {
            state.showOTP && <form onSubmit={resetPassword}>
                <section className="h-screen">
                    <div className="h-full bg-gradient-to-r from-blue to-blue-600 flex justify-center items-center">
                        <div className="p-5 bg-white rounded flex flex-col items-center">
                            <div>
                                <img className="object-contain h-20 w-32" src={StaticImage('/images/logoSCGP.svg')} />
                            </div>
                            <div>
                                <h1 className="font-bold text-3xl">Reset Password</h1>
                            </div>
                            <span className="text-base text-gray">ยินดีต้อนรับเข้าสู่ระบบบริหารเอกสารดิจิตอล</span>
                            <input value={state.confirmOTP}
                                onChange={(e) => {
                                    setstate({ ...state, confirmOTP: e.target.value })
                                }} required className="form-control w-72 mt-2" placeholder="OTP" type="text" />
                            <input value={state.newPassword}
                                onChange={(e) => {
                                    setstate({ ...state, newPassword: e.target.value })
                                }} required className={`form-control w-72 mt-1`} placeholder="New Password" type="password" />
                            <input value={state.confirmPassword}
                                onChange={(e) => {
                                    setstate({ ...state, confirmPassword: e.target.value })
                                }} required className={`form-control w-72 mt-1`} placeholder="Confirm Password" type="password" />
                            {state.messageError && <label className="text-red">{state.messageError}</label>}
                            <button type="submit" className="btn-red w-72 mt-2">Change Password</button>
                            <button type="button" onClick={(e) => { setstate({ ...state, showLogin: true, showRegister: false, showChangePassword: false, showOTP: false, messageError: "" }) }} className="btn-red w-72 mt-2">Sign in</button>
                        </div>
                    </div>
                </section>
            </form>
        }

        <Modal isModal={state.showOTPRegister}
            onClose={() => setstate({
                ...state, showOTPRegister: false
            })}
            onSubmit={() => {
                createNewAccountConfirmOtp();
            }}>
            <div className="w-60 flex items-center flex-col gap-2">
                <span className="text-base text-gray">ยืนยันรหัส OTP</span>
                <input value={state.confirmOTP}
                    onChange={(e) => {
                        setstate({ ...state, confirmOTP: e.target.value })
                    }} required className="form-control w-full mt-2" placeholder="OTP" type="text" />
                <span className="btn-link" onClick={(e) => createNewAccount(e)}>ขอรหัส OTP อีกครั้ง</span>
                <div className="w-full">
                    {state.messageErrorOTP && <label className="text-red">{state.messageErrorOTP}</label>}
                </div>
            </div>
        </Modal>
        <FooterLogin />
    </>
}
export default Login;  