import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, TrashIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ApiCheckUp from "../../api/ApiCheckup";
import ApiImage from "../../api/ApiImage";
import { CheckFile, dataURLtoFile } from "../../utils";
import CSwl from "../../utils/alert";
import loadImage from "blueimp-load-image/js";
import { useDispatch, useSelector } from "react-redux";
const Check = () => {
    const navigate = useNavigate();
    const profileState = useSelector((state) => state.profile);
    const [checkup, setCheckup] = useState({
        masterCheckups: [],
        tranCheckups: []
    });
    useEffect(() => {
        getCheckup();
    }, []);

    const getCheckup = async () => {
        CSwl.SwalLoad();
        const response = await ApiCheckUp.getCheckup(profileState.business_type);
        
        if (response.status == 200) {
            console.log(response)
            setCheckup({
                masterCheckups: response.data.masterCheckups,
                tranCheckups: response.data.tranCheckups
            });
        }
        CSwl.SwalClose();
        setTimeout(() => {
            if(localStorage.getItem('scrollPosition') !== null)
                window.scrollTo(0, localStorage.getItem('scrollPosition'));
        }, 500);
    }

    const titelList = [];
    checkup.masterCheckups.map((item, index) => {
        if (titelList.indexOf(item.title) === -1) {
            titelList.push(item.title);
            return item.title;
        }
        return null;
    })

    const finishCheckup = async () => {
        //console.log("batchItems::",batchItems)
        CSwl.SwalLoad();
        try {
            const response = await ApiCheckUp.finishCheckup(profileState.business_type);
            if (response.status == 200) {
                navigate('/jobTimeline');
            }
            else {
                CSwl.SwalErr(`${response.data}`);
            }

        } catch (error) {
            const { data } = error.response;
            CSwl.SwalErr(data.message)
        }
    }

    return <div className="pb-5">
        <nav class="flex bg-white text-gray border-b border-gray-200 py-3 px-5  mb-4" aria-label="Breadcrumb">
            <ol class="inline-flex items-center space-x-1 md:space-x-3">
                <li class="inline-flex items-center">
                    <Link to="/" className="text-gray-700 hover:text-gray-900 inline-flex items-center">
                        <svg class="w-5 h-5 mr-2.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                        หน้าแรก
                    </Link>
                </li>
                <li class="inline-flex items-center">
                    <Link to="/jobTimeline" className="text-gray-700 hover:text-gray-900 inline-flex items-center">
                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                        ไทม์ไลน์
                    </Link>
                </li>
                <li aria-current="page">
                    <div class="flex items-center">
                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                        <span class="text-red  ml-1 md:ml-2 font-medium">ตรวจความพร้อม</span>
                    </div>
                </li>
            </ol>
        </nav>
        <div className="px-3">
            {
                titelList.map((title, index) => {
                    return (
                        <>
                            <div className="flex">
                                <h1 className="text-2xl text-red">{title}</h1>
                            </div>
                            {checkup.masterCheckups.filter(x => x.title == title).map((item, index) => {
                                return (
                                    <div>
                                        <CheckItem key={item.id} title={item.detail} checkupId={item.id} checkupItem={checkup.tranCheckups.filter((s) => { return s.checkupId == item.id })} />
                                    </div>
                                )
                            })
                            }
                        </>
                    )
                })
            }
            <div className="flex justify-center mt-3 gap-x-2">
                <button className="btn-blue ml-2" onClick={() => { finishCheckup() }}>บันทึกข้อมูล</button>
            </div>
            {/* <div className="flex">
                <h1 className="text-2xl text-red">ตรวจสอบความพร้อมพนักงานขับรถ</h1>
            </div>
            <div>
                {checkup.masterCheckups.filter(x => x.title == "ตรวจความพร้อมพนักงานขับรถ").map((item, index) => {
                    return <CheckItem key={item.id} title={item.detail} />
                })}
            </div>
            <div className="flex mt-4">
                <h1 className="text-2xl text-red">ตรวจสภาพรถ</h1>
            </div>
            <div>
                {checkup.masterCheckups.filter(x => x.title == "ตรวจสภาพรถ").map((item, index) => {
                    return <CheckItem key={item.id} title={item.detail} />
                })}
            </div>
            <div className="flex mt-4">
                <h1 className="text-2xl text-red">อุปกรณ์ Safety</h1>
            </div>
            <div>
                {checkup.masterCheckups.filter(x => x.title == "อุปกรณ์ Safety").map((item, index) => {
                    return <CheckItem key={item.id} title={item.detail} />
                })}
            </div> */}
        </div>
    </div>
}

const CheckItem = ({ title, checkupId, checkupItem }) => {
    const [photosDefault, setphotos] = useState(checkupItem);
    const navigate = useNavigate();
    const [file, setfile] = useState("");
    const uploadFile = async (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];

            if (await CheckFile({ file: file, size: 10, type: ['image/gif', 'image/jpeg', 'image/png'] })) {
                CSwl.SwalLoad();
                loadImage(
                    file,
                    img => {
                        console.log(file);
                        var base64data = img.toDataURL(`image/jpeg`);
                        var fileNew = dataURLtoFile(base64data, file.name);
                        setfile(fileNew);
                        uploadFileApi(fileNew);
                        e.target.value = null;
                    },
                    { orientation: true, canvas: true }
                );

                // console.log("image::",image)
                //setfile(image);

                CSwl.SwalClose();
            }
        }
    }

    const uploadFileApi = async (file) => {
        localStorage.setItem('scrollPosition',window.scrollY);
        const response = await ApiImage.uploadImage(file);
        if (response.status == 200) {
            const responseSaveImage = await ApiImage.SaveCheckupImage({
                "checkupId": checkupId,
                "photoName": response.data.filename,
                "photoThumbnailName": response.data.thumbnainlname
            });
            if (responseSaveImage.status == 200) {
                setphotos([...photosDefault,
                {
                    id: responseSaveImage.data.imgid,
                    photoThumbnailName: responseSaveImage.data.thumbnailName,
                    checkupId: checkupId
                }])
            }
        }
    }



    const deleteImage = async (id) => {
        CSwl.SwalLoad();

        const response = await ApiImage.deleteImageCheckup(id);
        if (response.status == 200) {
            setphotos(photosDefault.filter(x => x.id != id))
            CSwl.SwalClose();
        }
        else {
            CSwl.SwalClose();
        }
    }



    return <Disclosure defaultOpen>
        {({ open }) => (
            <>
                <Disclosure.Button className={`flex w-full justify-between 
            ${open ? 'rounded-bl-none rounded-br-none ' : ''}
            border border-gray-200 px-4 py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75`}>
                    <span className="text-bold">{title}</span>
                    <ChevronDownIcon
                        className={`${open ? 'rotate-180 transform' : ''
                            } h-5 w-5 text-purple-500`}
                    />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 border border-gray-200 rounded-br-lg rounded-bl-lg gap-y-2 flex flex-col">
                    <div class="flex w-full items-center justify-center bg-grey-lighter">
                        <label class="w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
                            <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                            </svg>
                            {file ? <>
                                <span class="mt-2 text-base leading-normal">Upload a file ({file.name})</span>
                                <span class="text-sm text-gray">PNG, JPG, GIF up to 10MB</span>
                            </> : <>
                                <span class="mt-2 text-base leading-normal">Upload a file</span>
                                <span class="text-sm text-gray">PNG, JPG, GIF up to 10MB</span>
                            </>}

                            <input onChange={uploadFile} type='file' class="hidden" />
                        </label>
                    </div>
                    <div class="flex w-full items-center justify-center bg-grey-lighter flex flex-wrap gap-2">
                        {
                            photosDefault.map((item, index) => {
                                return <div className="relative min-w-[100px] max-w-[100px]">
                                    <img src={process.env.REACT_APP_IMAGE + item.photoThumbnailName} />
                                    <div onClick={() => {
                                        deleteImage(item.id)
                                    }} className="p-2 cursor-pointer absolute top-0 right-0 bg-white border border-[#cfcfcf]">
                                        <TrashIcon
                                            className={`h-5 w-5 text-red`}
                                        />
                                    </div>
                                </div>
                            })
                        }

                    </div>
                </Disclosure.Panel>
            </>
        )}
    </Disclosure>
}

const Check1 = () => {
    return <Disclosure>
        {({ open }) => (
            <>
                <Disclosure.Button className={`flex w-full justify-between 
            ${open ? 'rounded-bl-none rounded-br-none ' : ''}
            border border-gray-200 px-4 py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75`}>
                    <span className="text-bold">แฟ้มใส่เอกสาร/สมุดประจำตัวรถ</span>
                    <ChevronDownIcon
                        className={`${open ? 'rotate-180 transform' : ''
                            } h-5 w-5 text-purple-500`}
                    />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 border border-gray-200 rounded-br-lg rounded-bl-lg">
                    <div class="flex w-full items-center justify-center bg-grey-lighter">
                        <label class="w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
                            <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                            </svg>
                            <span class="mt-2 text-base leading-normal">Upload a file</span>
                            <span class="text-sm text-gray">PNG, JPG, GIF up to 10MB</span>
                            <input type='file' class="hidden" />
                        </label>
                    </div>
                </Disclosure.Panel>
            </>
        )}
    </Disclosure>
}

const Check2 = () => {
    return <Disclosure>
        {({ open }) => (
            <>
                <Disclosure.Button className={`flex w-full justify-between 
            ${open ? 'rounded-bl-none rounded-br-none ' : ''}
            border border-gray-200 px-4 py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75`}>
                    <span className="text-bold">เครื่องแบบพขร. (เสื้อ,กางเกงขายาว,รองเท้าหุ้มส้น)</span>
                    <ChevronDownIcon
                        className={`${open ? 'rotate-180 transform' : ''
                            } h-5 w-5 text-purple-500`}
                    />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 border border-gray-200 rounded-br-lg rounded-bl-lg">
                    <div class="flex w-full items-center justify-center bg-grey-lighter">
                        <label class="w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
                            <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                            </svg>
                            <span class="mt-2 text-base leading-normal">Upload a file</span>
                            <span class="text-sm text-gray">PNG, JPG, GIF up to 10MB</span>
                            <input type='file' class="hidden" />
                        </label>
                    </div>
                </Disclosure.Panel>
            </>
        )}
    </Disclosure>
}

const Check3 = () => {
    return <Disclosure>
        {({ open }) => (
            <>
                <Disclosure.Button className={`flex w-full justify-between 
            ${open ? 'rounded-bl-none rounded-br-none ' : ''}
            border border-gray-200 px-4 py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75`}>
                    <span className="text-bold">
                        อุปกรณ์PPE (เสื้อสะท้อนแสง,หมวกเซฟตี้,รองเท้าเซฟตี้)
                    </span>
                    <ChevronDownIcon
                        className={`${open ? 'rotate-180 transform' : ''
                            } h-5 w-5 text-purple-500`}
                    />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 border border-gray-200 rounded-br-lg rounded-bl-lg">
                    <div class="flex w-full items-center justify-center bg-grey-lighter">
                        <label class="w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
                            <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                            </svg>
                            <span class="mt-2 text-base leading-normal">Upload a file</span>
                            <span class="text-sm text-gray">PNG, JPG, GIF up to 10MB</span>
                            <input type='file' class="hidden" />
                        </label>
                    </div>
                </Disclosure.Panel>
            </>
        )}
    </Disclosure>
}

const Check4 = () => {
    return <Disclosure>
        {({ open }) => (
            <>
                <Disclosure.Button className={`flex w-full justify-between 
            ${open ? 'rounded-bl-none rounded-br-none ' : ''}
            border border-gray-200 px-4 py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75`}>
                    <span className="text-bold">
                        พื้นบรรทุกต้องสะอาด,ไม่เปียก,ไม่มีรอยแตกรั่ว
                    </span>
                    <ChevronDownIcon
                        className={`${open ? 'rotate-180 transform' : ''
                            } h-5 w-5 text-purple-500`}
                    />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 border border-gray-200 rounded-br-lg rounded-bl-lg">
                    <div class="flex w-full items-center justify-center bg-grey-lighter">
                        <label class="w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
                            <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                            </svg>
                            <span class="mt-2 text-base leading-normal">Upload a file</span>
                            <span class="text-sm text-gray">PNG, JPG, GIF up to 10MB</span>
                            <input type='file' class="hidden" />
                        </label>
                    </div>
                </Disclosure.Panel>
            </>
        )}
    </Disclosure>
}

const Check5 = () => {
    return <Disclosure>
        {({ open }) => (
            <>
                <Disclosure.Button className={`flex w-full justify-between 
            ${open ? 'rounded-bl-none rounded-br-none ' : ''}
            border border-gray-200 px-4 py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75`}>
                    <span className="text-bold">
                        สภาพยางรถ และล้อ
                    </span>
                    <ChevronDownIcon
                        className={`${open ? 'rotate-180 transform' : ''
                            } h-5 w-5 text-purple-500`}
                    />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 border border-gray-200 rounded-br-lg rounded-bl-lg">
                    <div class="flex w-full items-center justify-center bg-grey-lighter">
                        <label class="w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
                            <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                            </svg>
                            <span class="mt-2 text-base leading-normal">Upload a file</span>
                            <span class="text-sm text-gray">PNG, JPG, GIF up to 10MB</span>
                            <input type='file' class="hidden" />
                        </label>
                    </div>
                </Disclosure.Panel>
            </>
        )}
    </Disclosure>
}

const Check6 = () => {
    return <Disclosure>
        {({ open }) => (
            <>
                <Disclosure.Button className={`flex w-full justify-between 
            ${open ? 'rounded-bl-none rounded-br-none ' : ''}
            border border-gray-200 px-4 py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75`}>
                    <span className="text-bold">
                        เชือก สเตย์ รัดผ้าใบ
                    </span>
                    <ChevronDownIcon
                        className={`${open ? 'rotate-180 transform' : ''
                            } h-5 w-5 text-purple-500`}
                    />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 border border-gray-200 rounded-br-lg rounded-bl-lg">
                    <div class="flex w-full items-center justify-center bg-grey-lighter">
                        <label class="w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
                            <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                            </svg>
                            <span class="mt-2 text-base leading-normal">Upload a file</span>
                            <span class="text-sm text-gray">PNG, JPG, GIF up to 10MB</span>
                            <input type='file' class="hidden" />
                        </label>
                    </div>
                </Disclosure.Panel>
            </>
        )}
    </Disclosure>
}

const Check7 = () => {
    return <Disclosure>
        {({ open }) => (
            <>
                <Disclosure.Button className={`flex w-full justify-between 
            ${open ? 'rounded-bl-none rounded-br-none ' : ''}
            border border-gray-200 px-4 py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75`}>
                    <span className="text-bold">
                        ลิ่มรองล้อ พลาสติก
                    </span>
                    <ChevronDownIcon
                        className={`${open ? 'rotate-180 transform' : ''
                            } h-5 w-5 text-purple-500`}
                    />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 border border-gray-200 rounded-br-lg rounded-bl-lg">
                    <div class="flex w-full items-center justify-center bg-grey-lighter">
                        <label class="w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
                            <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                            </svg>
                            <span class="mt-2 text-base leading-normal">Upload a file</span>
                            <span class="text-sm text-gray">PNG, JPG, GIF up to 10MB</span>
                            <input type='file' class="hidden" />
                        </label>
                    </div>
                </Disclosure.Panel>
            </>
        )}
    </Disclosure>
}

const Check8 = () => {
    return <Disclosure>
        {({ open }) => (
            <>
                <Disclosure.Button className={`flex w-full justify-between 
            ${open ? 'rounded-bl-none rounded-br-none ' : ''}
            border border-gray-200 px-4 py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75`}>
                    <span className="text-bold">
                        กล้องหน้ารถ พร้อมใช้งาน
                    </span>
                    <ChevronDownIcon
                        className={`${open ? 'rotate-180 transform' : ''
                            } h-5 w-5 text-purple-500`}
                    />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 border border-gray-200 rounded-br-lg rounded-bl-lg">
                    <div class="flex w-full items-center justify-center bg-grey-lighter">
                        <label class="w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
                            <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                            </svg>
                            <span class="mt-2 text-base leading-normal">Upload a file</span>
                            <span class="text-sm text-gray">PNG, JPG, GIF up to 10MB</span>
                            <input type='file' class="hidden" />
                        </label>
                    </div>
                </Disclosure.Panel>
            </>
        )}
    </Disclosure>
}

export default Check;